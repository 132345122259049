import { axiosController } from '@/lib/network';
import { Result } from '@/lib/type';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';

export interface CreateCloudProviderRequest {
  org_id: string;
  platform: string;
  region: string;
  name: string;
  role_arn: string;
  role_external_id: string;
  vpc_owned_by_tg: boolean;
  vpc_id: string;
  subnet_id: string[];
  security_group_id: string;
  type: string;
  options: {
    secure_connection: boolean;
    account_id: string;
  };
}

export async function getCloudProviders(): Promise<Result<CloudProviderT[]>> {
  const response = await axiosController.get('/v2/cloud-providers');
  return response.data;
}

export async function getPublicCloudProviders(): Promise<Result<CloudProviderT[]>> {
  const response = await axiosController.get('/v2/cloud-providers/public');
  return response.data;
}

// export function getProviders() {
//   return { Result: Cloud_Providers };
// }

export async function createProvider(data: CreateCloudProviderRequest): Promise<Result<void>> {
  const res = await axiosController.post('/v2/cloud-providers', data);
  return res.data;
}

export async function deleteProvider(provider_id: string): Promise<Result<void>> {
  const res = await axiosController.delete(`/v2/cloud-providers/${provider_id}`);
  return res.data;
}

export async function updateProvider(provider_id: string, data: { name: string }): Promise<Result<void>> {
  const res = await axiosController.put(`/v2/cloud-providers/${provider_id}`, data);
  return res.data;
}

export interface ValidateCPRequest {
  region: string;
  role_arn: string;
  validate_vpc: boolean;
  role_external_id: string;
  vpc_id: string;
  subnet_id: string[];
  security_group_id: string;
  platform: string;
  secure_connection: boolean;
  account_id: string;
}
export interface ValidateCPResponse {
  insufficient_quotas:
    | {
        service: string;
        quota_code: string;
        required: number;
        available: number;
        limit: number;
      }[]
    | null;
  missing_permissions: string[] | null;
}
export async function validateCP(data: ValidateCPRequest) {
  const res = await axiosController.post<Result<ValidateCPResponse>>('/v2/cloud-providers/validate', data);
  return res.data;
}
